import Order from '@/Interfaces/Order';
import { TransactionType } from '@/enums/TransactionType';
import { PaymentType } from '@/enums/PaymentType';
import Item from '@/Interfaces/Item';
import Customer from '@/Interfaces/Customer';
import MarketPlace from '@/Interfaces/MarketPlace';

export default class SearchService {
  searchAndFilterOrders(
    orders: Order[],
    searchTerm: string,
    dateFilterFrom?: any,
    dateFilterTo?: any,
  ) {
    if (searchTerm.length > 0) {
      const search = new RegExp(searchTerm, 'gi');

      orders = orders.filter((order) => {
        return (
          order.id.match(search) ||
          (order.customer && order.customer.first_name.match(search)) ||
          (order.customer && order.customer.last_name.match(search)) ||
          order.total.toString().match(search) ||
          TransactionType[order.transactionType].match(search) ||
          PaymentType[order.paymentType].match(search) ||
          (order.accountType && order.accountType.match(search)) ||
          order.location.name.match(search) ||
          (order.createdBy && order.createdBy.email.match(search))
        );
      });
    }

    if (dateFilterFrom && dateFilterFrom.length > 0) {
      orders = orders.filter((order) => {
        return Date.parse(order.createdAt!) >= Date.parse(dateFilterFrom);
      });
    }

    if (dateFilterTo && dateFilterTo.length > 0) {
      const lastMillisecondInDay = 86399000;
      orders = orders.filter((order) => {
        return (
          Date.parse(order.createdAt!) <=
          Date.parse(dateFilterTo) + lastMillisecondInDay
        );
      });
    }

    return orders;
  }

  searchAndFilterMarketPlaces(items: MarketPlace[], searchTerm: any) {
    if (searchTerm.length > 0) {
      const search = new RegExp(searchTerm, 'gi');
      items = items.filter((item) => {
        const itemType = item.itemType === 1 ? 'Goods' : 'Services';

        return (
          item.name.match(search) ||
          (item.manufacturer !== null && item.manufacturer!.match(search)) ||
          item.variants.some((variant) => !!variant.sku.match(search)) ||
          itemType.match(search) ||
          item.variants.some(function (variant) {
            if (variant.upc == null) {
              variant.upc = '';
            }
            return !!variant.upc.match(search);
          })
        );
      });
    }
    return items;
  }

  searchAndFilterItems(items: Item[], searchTerm: string) {
    if (searchTerm.length > 0) {
      const search = new RegExp(searchTerm, 'gi');
      items = items.filter((item) => {
        const itemType = item.itemType === 1 ? 'Goods' : 'Services';
        if (item.locationDescription) {
          return (
            item.name.match(search) ||
            item.variants.some((variant) => !!variant.sku.match(search)) ||
            item.variants.some(
              (variant) => !!variant.price.toString().match(search),
            ) ||
            item.locationDescription!.match(search) ||
            (item.manufacturer !== null && item.manufacturer!.match(search)) ||
            itemType.match(search) ||
            item.variants.some(function (variant) {
              if (variant.upc == null) {
                variant.upc = '';
              }
              return !!variant.upc.match(search);
            })
          );
        } else {
          return (
            item.name.match(search) ||
            item.variants.some((variant) => !!variant.sku.match(search)) ||
            (item.manufacturer !== null && item.manufacturer!.match(search)) ||
            itemType.match(search) ||
            item.variants.some(function (variant) {
              if (variant.upc == null) {
                variant.upc = '';
              }
              return !!variant.upc.match(search);
            })
          );
        }
      });
    }
    return items;
  }

  searchAndFilterContacts(customers: Customer[], searchTerm: string) {
    if (searchTerm.length > 0) {
      customers = customers.filter((customer) => {
        return (
          (customer.first_name !== null &&
            customer.first_name
              .toLowerCase()
              .match(searchTerm.toLowerCase())) ||
          (customer.last_name !== null &&
            customer.last_name.toLowerCase().match(searchTerm.toLowerCase())) ||
          (customer.email !== null &&
            customer.email.toLowerCase().match(searchTerm.toLowerCase())) ||
          (customer.account_number !== null &&
            customer.account_number
              .toLowerCase()
              .match(searchTerm.toLowerCase()))
        );
      });
    }
    return customers;
  }
  searchAndFilterMarketplaceReports(items: any, searchTerm: string) {
    if (searchTerm.length > 0) {
      items = items.filter((item: any) => {
        return (
          (item.Item_Name !== null &&
            item.Item_Name.toLowerCase().match(searchTerm.toLowerCase())) ||
          (item.Manufacturer !== null &&
            item.Manufacturer.toLowerCase().match(searchTerm.toLowerCase())) ||
          (item.Variant !== null &&
            item.Variant.toLowerCase().match(searchTerm.toLowerCase())) ||
          (item.UPC !== null && item.UPC.match(searchTerm)) ||
          (item.Quantity !== null && item.Quantity.match(searchTerm)) ||
          (item.Tax !== null && item.Tax.match(searchTerm)) ||
          (item.Total !== null && item.Total.match(searchTerm)) ||
          (item.NetSell !== null && item.NetSell.match(searchTerm))
        );
      });
    }
    return items;
  }
}
