
import UserService from '../services/UserService';
import ContactService from '../services/ContactService';
import router from '@/router';
import SearchService from '@/services/SearchService';
import RoleService from '@/services/RoleService';
import LocalStorageService from '@/services/LocalStorageService';

import { mapActions, mapGetters } from 'vuex';

import Pagination from '@/shared/Pagination.vue';
import { defineComponent } from 'vue';

interface ComponentData {
  textCount: number;
  searchTimeout: any;
  isDeletePopupBlock: boolean;
  deleteId: string;
  deleteIndex: number;
  userService: UserService;
  contactService: ContactService;
  searchService: SearchService;
  roleService: RoleService;
  localStorageService: LocalStorageService;
  customers: any[];
  filteredContacts: any[];
  selectedUser: any;
  changes: any[];
  showAuditTable: false;
  searchTerm: string;
  loading: boolean;
  locations: any[];
  selectedRoleId: string;
  roleOptions: { value: string; text: string }[];
  locationOptions: { value: string; text: string }[];
  selectedLocationId: string;
  noData: boolean;
  isSuperadminLoc: boolean;
  page: number;
  pageSize: number;
  previousNextDisable: boolean;
  arrowAscName: boolean;
  valueName: string;
  sortingValue: string;
  searchFieldValue: string;
  arrowAscEmail: boolean;
  arrowAscLastName: boolean;
  arrowAscAccountNo: boolean;
  totalCount: number;
  currentPage: number;
  nextPage: number;
  lastPage: number;
  previusePage: number;
  records: number;
  alertTitle: string;
  alertMessage: string;
  showConfirm: boolean;
  isDisabled: boolean;
}

export default defineComponent({
  name: 'Contacts',
  components: {
    Pagination,
  },
  data() {
    const data: ComponentData = {
      textCount: 0,
      searchTimeout: undefined,
      isDeletePopupBlock: false,
      deleteId: '',
      deleteIndex: 0,
      userService: undefined,
      contactService: undefined,
      searchService: undefined,
      roleService: undefined,
      localStorageService: undefined,
      customers: [],
      filteredContacts: [],
      selectedUser: {},
      changes: [],
      showAuditTable: false,
      searchTerm: '',
      loading: false,
      locations: [],
      selectedRoleId: '',
      roleOptions: [{ value: '', text: 'Please select a role' }],
      locationOptions: [{ value: '', text: 'Please select a location' }],
      selectedLocationId: undefined,
      noData: true,
      isSuperadminLoc: false,
      page: 1,
      pageSize: 15,
      previousNextDisable: true,
      arrowAscName: true,
      valueName: '',
      sortingValue: 'ASC',
      searchFieldValue: '',
      arrowAscEmail: true,
      arrowAscLastName: true,
      arrowAscAccountNo: true,
      totalCount: 0,
      currentPage: 1,
      nextPage: 0,
      lastPage: 0,
      previusePage: 0,
      records: 0,
      alertTitle: '',
      alertMessage: '',
      showConfirm: false,
      isDisabled: true,
    };
    return data;
  },
  created() {
    // this.getContactList();
    this.userService = new UserService();
    this.contactService = new ContactService();
    this.searchService = new SearchService();
    this.roleService = new RoleService(new LocalStorageService());
    this.localStorageService = new LocalStorageService();
  },
  async mounted() {
    this.selectedLocationId = this.localStorageService.getItem(
      'primary_location_id',
    );
    this.loading = true;
    if (this.roleService.isStaff) {
      this.isDisabled = false;
    }
    await this.$store.dispatch('getLocationList');
    this.locations = this.$store.getters.allLocationList;
    this.setLocationOptions();
    if (this.$store.getters.contactFilter.locationId) {
      this.selectedLocationId = this.$store.getters.contactFilter.locationId;
      await this.changeLocation();
    } else if (this.selectedLocationId) {
      await this.changeLocation();
    }
    this.loading = false;
  },
  methods: {
    ...mapActions(['getContactList', 'setContactFilter']),

    goToPage(payload) {
      this.getAllItems(payload.page);
    },

    shouldShowDelete(customer: any) {
      // If user is support hide contact button
      if (this.roleService.isSupport) {
        return false;
      }
      const locationIds = this.localStorageService.getItem('locations');
      if (
        customer.location &&
        locationIds &&
        locationIds.includes(customer.location.id)
      ) {
        return true;
      }
      if (
        !customer.location &&
        locationIds &&
        locationIds.includes(this.selectedLocationId)
      ) {
        return true;
      }
    },

    changeLocation() {
      this.filteredContacts = [];
      this.isSuperadminLoc = false;
      const locationOfSuperadmin =
        this.localStorageService.getItem('locations');
      if (
        locationOfSuperadmin &&
        this.roleService.isSuperadmin &&
        locationOfSuperadmin.slice(2, -2) === this.selectedLocationId
      ) {
        this.isSuperadminLoc = true;
      }
      if (this.selectedLocationId !== null) {
        this.localStorageService.store(
          'primary_location_id',
          this.selectedLocationId,
        );
        const filter = this.$store.getters.contactFilter;
        filter.search = this.searchFieldValue;
        filter.locationId = this.selectedLocationId;
        this.$store.dispatch('setContactFilter', filter).then(() => {
          this.getAllItems(this.currentPage);
        });
      }
    },

    getAllItems(page: any, searchNumber?: number) {
      this.loading = true;
      const filter = this.$store.getters.contactFilter;
      filter.page = page;
      filter.pageSize = this.pageSize;
      this.$store
        .dispatch('setContactFilter', filter)
        .then(() => {
          this.$store
            .dispatch('getContactList')
            .then(() => {
              if (!searchNumber || this.textCount == searchNumber) {
                this.loading = false;
                this.customers = this.$store.getters.allcontactList;
                this.records = this.$store.getters.contactFilter.totalCount;
                this.totalCount = Math.ceil(this.records / this.pageSize);
                this.filteredContacts = this.customers;
                this.currentPage = page;
                this.lastPage = this.$store.getters.contactFilter.lastPage;
                // IVI-418
                // this.nextPage = 0;
                // this.previousPage = this.currentPage - 1;
                // if (this.currentPage < this.totalCount) {
                //   this.nextPage = this.currentPage + 1;
                // }
                this.noData = true;
                if (this.customers.length) {
                  this.noData = false;
                }
              }
            })
            .catch(() => {
              this.noData = true;
              this.loading = false;
            });
        })
        .catch(() => {
          this.loading = false;
        });
    },

    deleteContacts(id: string, index: number) {
      this.isDeletePopupBlock = true;
      this.deleteId = id;
      this.deleteIndex = index;
    },

    async confirmationDeleteOk() {
      await this.contactService.deleteContactById(this.deleteId);
      this.$store.getters.allcontactList.splice(this.deleteIndex, 1);
      this.alertTitle = 'success';
      this.alertMessage = 'Contact deleted successfully!';
      this.showConfirm = true;
      this.isDeletePopupBlock = false;
      this.getAllItems(this.currentPage);
    },

    selected(customer: any) {
      router.push({
        path: `/contacts/${customer.id}`,
        query: { locationId: this.selectedLocationId },
      });
    },

    editSelected(customer: any) {
      router.push({
        path: `/contacts/${customer.id}/edit`,
        query: { locationId: this.selectedLocationId },
      });
    },

    async setReceiveEmails(userId: string, event: any) {
      await this.userService.setReceiveEmails(userId, event);
      this.showAuditTable = false;
    },

    async toggleShowAuditTable() {
      this.changes = await this.userService.getAuditTrailForUser();
      this.showAuditTable = !this.showAuditTable;
    },

    getAuditButtonVerb() {
      if (this.showAuditTable) return 'Hide';
      else return 'Show';
    },

    contactSearch() {
      this.filteredContacts = this.searchService.searchAndFilterContacts(
        this.customers,
        this.searchTerm,
      );
    },

    setLocationOptions() {
      this.locationOptions = this.locationOptions.concat(
        this.locations.map((location: any) => {
          return { value: location.id, text: location.name };
        }),
      );
      if (this.roleService.isStaffOrAdmin) {
        const locationOfUsers = this.localStorageService.getItem('locations');
        if (locationOfUsers != null) {
          const _locationOfUsers = JSON.parse(locationOfUsers);
          if (_locationOfUsers.length === 1) {
            this.selectedLocationId =
              _locationOfUsers[0].id ||
              this.localStorageService.getItem('primary_location_id');
          }
        }
      }
    },

    handleSearchField(event: any, para: string, para2: string) {
      this.textCount++;
      const temp = this.textCount;
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => {
        this.searchFieldValue = event.target.value;
        const filter = this.$store.getters.contactFilter;
        filter.search = this.searchFieldValue;
        filter.searchField = para;
        filter.orderBy = this.sortingValue;
        filter.orderByField = para;

        if (filter.search === '') {
          const existingObjectIndex = filter.finalSearch.findIndex(
            (item) => item.searchField === filter.searchField,
          );
          if (existingObjectIndex !== -1) {
            filter.finalSearch.splice(existingObjectIndex, 1);
          }
        } else {
          const existingObjectIndex = filter.finalSearch.findIndex(
            (item) => item.searchField === filter.searchField,
          );

          if (existingObjectIndex !== -1) {
            filter.finalSearch[existingObjectIndex].search = filter.search;
          } else {
            filter.finalSearch.push({
              searchField: filter.searchField,
              search: filter.search,
            });
          }
        }
        this.$store.dispatch('setContactFilter', filter).then(() => {
          this.getAllItems(1, temp);
        });
      }, 1000);
    },

    handleSorting(para1: any, para2: any) {
      if (para1 === 'first_name') {
        this.arrowAscName = !this.arrowAscName;
        this.sortingValue = para2;
      } else if (para1 === 'last_name') {
        this.arrowAscLastName = !this.arrowAscLastName;
        this.sortingValue = para2;
      } else if (para1 === 'email') {
        this.arrowAscEmail = !this.arrowAscEmail;

        this.sortingValue = para2;
      } else if (para1 === 'account_number') {
        this.arrowAscAccountNo = !this.arrowAscAccountNo;
        this.sortingValue = para2;
      }
      const filter = this.$store.getters.contactFilter;

      filter.search = this.searchFieldValue;
      filter.searchField = para1;
      filter.orderBy = this.sortingValue;
      filter.orderByField = para1;

      this.$store.dispatch('setContactFilter', filter).then(() => {
        this.getAllItems(1);
      });
    },

    positiveButton() {
      this.showConfirm = false;
    },

    negativeButton() {
      this.showConfirm = false;
    },

    pageEvent(data: any) {
      this.page = data.page;
      this.getAllItems(this.page);
    },

    setPageSize(pageSize: number) {
      this.pageSize = pageSize;
      (this.page = 1), this.getAllItems(1);
    },
  },
  computed: mapGetters(['allcontactList', 'contactFilter']),
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'new contact') {
      this.$store.commit('resetContactFilter');
    }
    next();
  },
});
